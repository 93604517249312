import { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const Wrapper = ({ children }) => {
  const location = useLocation();
  const isBackForwardNavRef = useRef(false);

  useLayoutEffect(() => {
    const handlePopState = () => {
      isBackForwardNavRef.current = true;
      const scrollPosition = sessionStorage.getItem(`scrollPosition_${location.pathname}`);
      if (scrollPosition !== null) {
        setTimeout(() => {
          window.scrollTo({ top: parseInt(scrollPosition, 10), left: 0 });
          sessionStorage.removeItem(`scrollPosition_${location.pathname}`);
        }, 0);
      } else {
        window.scrollTo({ top: 0, left: 0 });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location]);

  useLayoutEffect(() => {
    if (isBackForwardNavRef.current) {
      isBackForwardNavRef.current = false;
      const scrollPosition = sessionStorage.getItem(`scrollPosition_${location.pathname}`);
      if (scrollPosition !== null) {
        setTimeout(() => {
          window.scrollTo({ top: parseInt(scrollPosition, 10), left: 0 });
          sessionStorage.removeItem(`scrollPosition_${location.pathname}`);
        }, 0);
      } else {
        window.scrollTo({ top: 0, left: 0 });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }
  }, [location.pathname]);

  useLayoutEffect(() => {
    const saveScrollPosition = (event) => {
      sessionStorage.setItem(`scrollPosition_${location.pathname}`, window.scrollY);
    };

    const trackedLinks = document.querySelectorAll('a.track-scroll');
    trackedLinks.forEach(link => link.addEventListener('click', saveScrollPosition));

    return () => {
      trackedLinks.forEach(link => link.removeEventListener('click', saveScrollPosition));
    };
  }, [location.pathname]);

  return children;
};

export default Wrapper;


