import { useState } from 'react';
import "./Button.css";


function Button(params) {
   const [viewport, setViewport] = useState(document.documentElement.clientWidth);
   window.addEventListener('resize', () => {
      //  viewport = document.documentElement.clientWidth;
      setViewport(document.documentElement.clientWidth);
   });
   let buttonClasses = `store-button ${viewport >= 992 ? "desktop" : "mobile"}`;
   let textClases = `text ${viewport >= 992 ? "desktop" : "mobile"}`;
   let downloadClasses = `download ${viewport >= 992 ? "desktop" : "mobile"}`;
   let mobileIconClasses = `store-icon mobile ${viewport >= 992 ? "hidden" : ""}`;
   let iconClasses = `store-icon ${viewport >= 992 ? "" : "hidden"}`;
   let downloadTextClasses = `download-text ${viewport >= 992 ? "desktop" : "mobile"}`;
   let storeClases = `download-text store ${viewport >= 992 ? "desktop" : "mobile"}`;
   let qrClasses = `qr ${viewport >= 992 ? "desktop" : "mobile"}`;


   return <a className={buttonClasses} href={params.href} target= {params.storeName === "App Store"? "_self":"_blank" }>
      <img className={mobileIconClasses} src={params.icon} alt="" />
      <span className={textClases}>
         <span className={downloadClasses}>
            <img className={iconClasses} src={params.icon} alt="" />
            <span className={downloadTextClasses}>{params.accompanyingText}</span>
         </span>
         <span className={storeClases}>{params.storeName}</span>
      </span>
      <img className={qrClasses} src={params.qr} alt="" />

   </a>


}

export default Button;