import "./ComingSoon.css";
import { useState } from "react";

function ComingSoon() {
    return <>
        <div id="coming-soon-container">
            <img id="coming-soon-container-image" src="images/store-buttons/Phone.png"></img>
            <span id="coming-soon-container-header">Coming Soon to the App Store!</span>
            <span id="coming-soon-container-content">Our app will be available for download soon. Stay tuned and check back later to enjoy seamless access to our features right on your device!</span>
            <a href = "/" id="coming-soon-container-button">Go to Home Page</a>
        </div>
    </>

}

export default ComingSoon;