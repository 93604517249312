import { useState } from 'react';
import "./Dataflow.css";
import DataLine from './DataLIne/DataLine';




function Dataflow() {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });
    let containerClasses = `section dataflow-container anchor-2 ${viewport >= 992 ? "desktop" : "mobile"}`;
    let headerClasses = `header ${viewport >= 992 ? "desktop" : "mobile"}`;

    let innerClasses = `row inner-container ${viewport >= 992 ? "desktop" : "mobile"}`;
    let imageClases = `dataflow-image col12 col-lg-6 ${viewport >= 992 ? "desktop" : "mobile"}`;
    let circleClasses = `dataflow-red-circle ${viewport >= 992 ? "desktop" : "mobile"}`;
    let textClasses = `dataflow-text col12 col-lg-6 ${viewport >= 992 ? "desktop" : "mobile"}`


    return <div id="dataflow" className={containerClasses} >
        <h2 className={headerClasses}>Dataflow</h2>

        <div className={innerClasses}>
            <div className={imageClases}>
                <div className={circleClasses}> </div>
                <img src="./images/dataflow/Dataflow.webp" alt="" />
            </div>
            <div className={textClasses}>
                <DataLine number={1} text={"Statistics for analyzing the performance of shooters"} />
                <DataLine number={2} text={"Support for multiple users role"} />
                <DataLine number={3} text={"QR synchronization"} />
                <DataLine number={4} text={"System management"} />
                <DataLine number={5} text={"Automatic evaluation and registration of shots"} />
                <DataLine number={6} text={"Data transfer to cloud storage"} />
            </div>
        </div>

    </div>
}

export default Dataflow;