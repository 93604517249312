import "./PrivacyPolicy.css";
import { useState } from "react";

function PrivacyPolicy() {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });

    let headerClasses = `header ${viewport >= 992 ? "desktop" : "mobile"}`;
    let contentClasses = `${viewport >= 992 ? "desktop" : "mobile"}`;

    return <div id="privacy-policy-container">
        <h2 className={headerClasses} id="privacy-policy-header">Privacy Policy</h2>
        <div className={contentClasses} id="privacy-policy-content">
            <h3 className="privacy-policy-paragraph-header">Effective Date: 23 December, 2024</h3>
            <h3 className="privacy-policy-paragraph-header">1. Introduction</h3>
            <ul>At ArmorX, we are committed to protecting your privacy and ensuring the security of your personal data. This Privacy Policy outlines how we collect, use, store, and safeguard information associated with the automated shooting range control system, its software, cloud-based services, our website (https://armorx.tech/), main application, and mobile app. </ul>
            <h3 className="privacy-policy-paragraph-header">2. Data We Collect</h3>
            <ul>We may collect the following types of information:
                <li>Personal Information: Name, email address, phone number, and other identifiers provided during account creation or communication.</li>
                <li>Usage Data: Statistics on user performance, target range, and shot accuracy.</li>
            </ul>
            <h3 className="privacy-policy-paragraph-header">3. How We Use Your Data</h3>
            <ul>The data we collect is used for the following purposes:
                <li>To provide and maintain the ArmorX system.</li>
                <li>To analyze user performance and generate statistics.</li>
                <li>To enable QR synchronization for multiple user roles.</li>
                <li>To improve system functionality through updates and AI algorithm enhancements.</li>
                <li>To store and back up data securely in the cloud.</li>

            </ul>
            <h3 className="privacy-policy-paragraph-header">4. How We Protect Your Data</h3>
            <ul>We use secure servers and modern encryption technologies to ensure the safety of your shooting data. Our systems are regularly updated to address potential vulnerabilities and maintain data integrity.
            </ul>
            <h3 className="privacy-policy-paragraph-header">5. Data Storage and Security</h3>
            <ul>User data is stored in secure cloud infrastructure and protected by industry-standard encryption methods. ArmorX employs advanced technologies to safeguard against unauthorized access, data breaches, and other risks.</ul>
            <h3 className="privacy-policy-paragraph-header">6. Cookies and Tracking</h3>
            <ul>We may use cookies and similar tracking technologies to enhance your experience with the ArmorX system. You can manage your cookie preferences through your browser settings.
            </ul>
            <h3 className="privacy-policy-paragraph-header">7. User Rights</h3>
            <ul>As a user, you have the following rights:
                <li>Access Your Data: The ability to view all shooting data associated with your account.</li>
                <li>Modify or Delete Data: Request corrections or deletion of your data, subject to technical feasibility.</li>
                <p>
                    To exercise your rights, contact us at info@improvismail.com.
                </p>
            </ul>
            <h3 className="privacy-policy-paragraph-header">8. Data Retention</h3>
            <ul>ArmorX retains user data only as long as necessary for the purposes outlined in this policy or as required by law.</ul>
            <h3 className="privacy-policy-paragraph-header">9. Changes to This Privacy Policy</h3>
            <ul>We may update this Privacy Policy to reflect changes in our practices or applicable regulations. Users will be notified of significant updates through email or system notifications.</ul>
            <h3 className="privacy-policy-paragraph-header">10. Contact Information</h3>
            <ul>For any questions or concerns regarding this Privacy Policy, contact us at:
                <li>Email: info@improvismail.com</li>
                <li>Phone: +374 41 35 45 51</li>
                <li>Address: 58 Kamarak st., Yerevan, Armenia</li>
            </ul>


        </div>
    </div>

}

export default PrivacyPolicy;