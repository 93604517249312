import "./Footer.css"
import "../GetInTouch copy/ContactLink/ContactLink.css"
import ContactLink from "../GetInTouch copy/ContactLink/ContactLink";
import { useState } from "react";

function Footer(params) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });
    let footerClasses =`footer ${viewport >= 992 ? "desktop" : "mobile"}`;

    return <div className={footerClasses}>
        <p  id = "footer-copyright" className='copyright contact-text'>© 2024 Improvis Design. All Rights Reserved.</p>
        <ContactLink href={"https://maps.app.goo.gl/8hEH6cSWkwh1XQmeA"} icon={"./images/getInTouch/Location.svg"} text={"58 Kamarak st., Yerevan, Armenia"} />
        <ContactLink href={"mailto: info@improvis.ai"} icon={"./images/getInTouch/Mail.svg"} text={"info@improvismail.com"} />
        <ContactLink href={"tel:+374 41 354 551"} icon={"./images/getInTouch/Phone.svg"} text={"+374 41 354 551"} />
    </div>
}

export default Footer;