import { useState } from 'react';
import "./InputField.css"

function InputField(params) {

    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });


    function validityMessageShow(e) {

        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;


        if (!e.target.value) {

            validityMessage.innerHTML = "Please fill out this field";
        }


        else if (e.target.id === "email") {
            validityMessage.innerHTML = "Please enter a valid email address";
        }
        validityMessage.style.display = "block";

    }
    function handleFocus(e) {
        if (params.addPlusSymbol) {
            params.addPlusSymbol(e);
        }
    }

    function handleInput(e) {
        if (e.target.id === "phone") {
            if (e.target.value.trim() == "" || e.target.value.isEmpty) {
                e.target.value = '+';
            }
        }
        validityMessageHide(e);

    }

    function validityMessageHide(e) {

        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;
        validityMessage.innerHTML = "";
        validityMessage.style.display = "none";

    }


    function inputBlur(e) {
        if (e.target.value.trim() === "") {
            e.target.value = ""
        }
        params.setInput(e.target.value);
        if (params.id === "phone") {
            params.removePlusSymbol(e);
        }
    }

    function enterKeySet(e) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }

    let groupClasses = `group ${params.class} ${viewport >= 992 ? "desktop" : "mobile"}`
    let inputClasses = `inputField  ${params.inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} `
    let validityClasses = `validity-message  ${viewport >= 992 ? "desktop" : "mobile"}`;
    let placeholderClasses = `placeholder ${params.inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} }`


    return <div className={groupClasses}>
        <input
            className={inputClasses}
            id={params.id}
            type={params.type}
            onKeyDown={enterKeySet}
            onInput={handleInput}
            onFocus={handleFocus}
            onBlur={inputBlur}
            onInvalid={validityMessageShow}
            required></input>
        <label className={placeholderClasses}>{params.placeholder}</label>
        <span className={validityClasses}></span>
    </div>
}

export default InputField;