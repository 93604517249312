import "./TermsOfUse.css";
import { useState } from "react";

function TermOfUse() {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });

    let headerClasses = `header ${viewport >= 992 ? "desktop" : "mobile"}`;
    let contentClasses = `${viewport >= 992 ? "desktop" : "mobile"}`;

    return <div id="term-of-use-container">
        <h2 className={headerClasses} id="term-of-use-header">Terms of Use</h2>
        <div className={contentClasses} id="term-of-use-content">
            <h3 className="term-of-use-paragraph-header">Effective Date: 23 December, 2024</h3>
            <h3 className="term-of-use-paragraph-header">1. Introduction</h3>
            <ul>ArmorX, a product of Improvis LLC (“we,” “us,” “our,” or “ArmorX”), provides advanced automated control systems for shooting ranges (“Services”). These Terms of Use (“Terms”) apply to the ArmorX system, including the software, mobile and desktop applications, and any associated services or devices that include a link to this document (collectively, the “System”). By accessing or using the System, you agree to these Terms. Your use of the System is also governed by any applicable separate agreement you may have with us, whether manually or digitally executed. We provide the System, including all software, data storage, and performance analysis tools, conditioned upon your acceptance of these Terms in their entirety.
            </ul>
            <h3 className="term-of-use-paragraph-header">2. Acceptance of Terms</h3>
            <ul>If you are using the system on behalf of an organization, you represent that you have the authority to bind that organization to these terms.
            </ul>
            <h3 className="term-of-use-paragraph-header">3. System Overview</h3>
            <ul>The ArmorX system is an advanced shooting range solution designed for associations, athletes, coaches, and amateurs. It includes:
                <li>Automated control system software for shooting ranges.</li>
                <li>Real-time computer vision based shot detection.</li>
                <li>Mobile and desktop applications.</li>
                <li>QR synchronization for streamlined access.</li>
                <li>Cloud-based storage for data management.</li>

            </ul>
            <h3 className="term-of-use-paragraph-header">4. User Responsibilities</h3>
            <ul>When using the ArmorX system, you agree to:
                <li>Use the system only for lawful purposes.</li>
                <li>Refrain from modifying, reproducing, or reverse - engineering system components.</li>
                <li>Safeguard your account credentials and notify us of unauthorized access.</li>
                <li>Accept responsibility for all activities under your account.</li>
            </ul>
            <h3 className="term-of-use-paragraph-header">5. Features and Functionalities</h3>
            <ul> The system offers the following features:
                <li>Computer Vision driven Software System: Automatically captures, evaluates, and manages shooting scores with precision using cutting-edge artificial intelligence.</li>
                <li>Cloud - Based Synchronization: Offers real - time data updates accessible through mobile applications for iOS and Android, ensuring a seamless experience.</li>
                <li>Performance Tracking Tools: Provides in -depth analysis of performance data with detailed progress tracking, designed specifically for athletes and coaches.</li>
                <li>Personalized User Experience: Tailored design to meet individual preferences, ensuring a user - centric and customized approach.</li>
                <li>Leaderboards for Engagement: Supports competition on local and global scales, fostering community interaction.</li>
                <li>Social Sharing Functionality: Enables effortless sharing of achievements and updates directly to social media platforms.</li>
            </ul>
            <h3 className="term-of-use-paragraph-header">6. Support</h3>
            <ul>The ArmorX system offers a comprehensive solution for its users.It includes not only the physical device but also professional installation and setup services to ensure seamless integration into your workflow.Additionally, it covers technical support for resolving any system - related issues, providing peace of mind for users.To keep the system up - to - date, free software updates are included, ensuring long - term functionality and access to the latest features and improvements without any additional cost.
            </ul>
            <h3 className="term-of-use-paragraph-header">7. Data Usage and Privacy</h3>
            <ul>By using ArmorX, you consent to the collection, processing, and storage of performance data for analysis and reporting purposes.Your data will be handled in accordance with applicable privacy laws.
            </ul>
            <h3 className="term-of-use-paragraph-header">8. Intellectual Property</h3>
            <ul>All components of the ArmorX system, including software, applications, and designs, are the exclusive property of Improvis.You may not copy, modify, or distribute any part of the system without prior written consent.
            </ul>
            <h3 className="term-of-use-paragraph-header">9. Limitation of Liability</h3>
            <ul>Improvis is not responsible for any misuse or unauthorized use of the ArmorX system by users or third parties.Additionally, it assumes no liability for technical failures that result from third - party interference, environmental factors, or any external circumstances beyond its control.The company also disclaims responsibility for indirect, incidental, or consequential damages, including but not limited to financial losses or operational disruptions, that may arise from the use or inability to use the system.
            </ul>
            <h3 className="term-of-use-paragraph-header">10. Modifications to Terms</h3>
            <ul>Improvis  reserves the right to update or modify these Terms of Use at any time.Users will be notified of significant changes via email or system notification.Continued use of the system constitutes acceptance of the revised terms.
            </ul>
            <h3 className="term-of-use-paragraph-header">11. Governing Law</h3>
            <ul>These terms are governed by the laws of the jurisdiction where Improvis operates.</ul>
            <h3 className="term-of-use-paragraph-header">12. Contact Information</h3>
            <ul>For any questions or concerns regarding this Terms Of Use, contact us at:
                <li>Email: info@improvismail.com</li>
                <li>Phone: +374 41 35 45 51</li>
                <li>Address: 58 Kamarak st., Yerevan, Armenia</li>
            </ul>


        </div>
    </div>

}

export default TermOfUse;

